import styled from "styled-components";

export const Animated = styled.div`
  position: relative;
  display: block;
  align-items: center;
  overflow: hidden;
  min-height: 82px;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 40px;
  padding-right: 40px;
`;

export const Title = styled.p`
  font-family: "Press Start 2P";
  font-size: 18px;
  cursor: pointer;

  @media only screen and (max-width: 767px) {
    color: #000000;
    text-align: center;
  }
`;

export const Link = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  padding: 5px 20px 0 5px;
  cursor: pointer;

  @media only screen and (max-width: 767px) {
    color: #000000;
    text-align: center;
    padding: 0;
  }
`;

export const ExternalLink = styled.a`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  padding: 5px 20px 0 5px;
  cursor: pointer;
  text-decoration: none;
  color: #ffffff;

  @media only screen and (max-width: 767px) {
    color: #000000;
    text-align: center;
    padding: 1em 0;
  }
`;

export const LinksContainer = styled.div`
  display: flex;
  position: absolute;
  top: 6px;
  justify-content: space-between;
  width: 100%;

  @media only screen and (max-width: 767px) {
    display: none;
  }
`;

export const MenuMobile = styled.div`
  @media only screen and (min-width: 767px) {
    display: none;
  }
`;

export const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #effffa;
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-100%)")};
  text-align: left;
  padding: 2rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transition: transform 0.3s ease-in-out;
  z-index: 999;
`;

export const StyledBurger = styled.button`
  position: absolute;
  top: 3%;
  left: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 9999;

  &:focus {
    outline: none;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background: ${({ open }) => (open ? "#0D0C1D" : "#EFFFFA")};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? "0" : "1")};
      transform: ${({ open }) => (open ? "translateX(20px)" : "translateX(0)")};
    }

    :nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`;
