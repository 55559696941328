import {
    Networks,
    GearPunksABI,
    CryptoPunksABI,
    PunkDataABI,
    MINT_GAS_LIMIT
} from "../constants/blockchain";
import { useWallet } from 'use-wallet'
import Web3 from 'web3'

const gearPunkEvents = async (contract, account) => {
    // @todo add starting block
    // @todo validate balance of
    const events = await contract.getPastEvents('Transfer', { filter: { to: account }, fromBlock: 'earliest' })
    const transfers = events.map(({ returnValues }) => returnValues?.tokenId ).filter(tokenId => tokenId < 10000)
    const carOwners = await Promise.all(transfers.map(async tokenId => {
        return {
            tokenId,
            owner: await contract.methods.ownerOf(tokenId).call()
        }
    }))
    return carOwners.filter(item => item.owner === account).map(item => item.tokenId)
}

const getPunkEvents = async (contract, account) => {
    // @todo add starting block
    // @todo validate balance of
    const transferEvents = await contract.getPastEvents('PunkTransfer', { filter: { to: account }, fromBlock: 'earliest' })
    const assignEvents = await contract.getPastEvents('Assign', { filter: { to: account }, fromBlock: 'earliest' })
    const buyEvents = await contract.getPastEvents('PunkBought', { filter: { toAddress: account }, fromBlock: 'earliest' })
    
    const transfers = [
        ...transferEvents,
        ...assignEvents,
        ...buyEvents
    ].map(({ returnValues }) => returnValues?.punkIndex )

    const punkOwners = await Promise.all(transfers.map(async tokenId => {
        return {
            tokenId,
            owner: await contract.methods.punkIndexToAddress(tokenId).call()
        }
    }))

    return punkOwners.filter(item => item.owner === account).map(item => item.tokenId)
}

export const useGearPunks = () => {
    const wallet = useWallet()
    const web3 = new Web3(wallet.ethereum)

    const gearPunks = new web3.eth.Contract(GearPunksABI, Networks[wallet.chainId].gearPunks)
    const cryptoPunks = new web3.eth.Contract(CryptoPunksABI, Networks[wallet.chainId].cryptoPunks)
    const punkData = new web3.eth.Contract(PunkDataABI, Networks[wallet.chainId].punkData)

    let price = null
    if(wallet.isConnected()) {
        gearPunks.methods.price().call().then( value => price = value)    
    }

    return {
        mint: () => price ? gearPunks.methods.mint().send({ from: wallet.account, value: price, gasLimit: MINT_GAS_LIMIT }) : null,
        hopIn: (carId, punkId) => gearPunks.methods.hopIn(carId, punkId).send({ from: wallet.account}),
        hopOff: (carId) => gearPunks.methods.hopOff(carId).send({ from: wallet.account}),
        balanceOf: () => gearPunks.methods.balanceOf(wallet.account).call(),
        getMetadata: async (tokenId) => {
            const metadata = await gearPunks.methods.tokenURI(tokenId).call()
            return {
                metadata,
                id: tokenId
            }
        },
        getCarTransfers: () => gearPunkEvents(gearPunks, wallet.account),
        // getPunkTransfers: () => getPunkEvents(cryptoPunks, '0xc8c115dcb2910ee8f3dbac4056b5448c4f624bde'),
        getPunkTransfers: () => getPunkEvents(cryptoPunks, wallet.account),
        readPunk: async (punkId) => {
            const metadata = await punkData.methods.punkImageSvg(punkId).call()
            return {
                metadata,
                id: punkId
            }
        }
    }
}