import Header from "../../components/Header";
import GarageSection from "../../components/GarageSection";
import ShowCase from "../../components/Showcase";
import Footer from "../../components/Footer";
import GarageDisplay from "../../components/GarageDisplay";
import React from "react";

const Home = () => {
  return (
    <React.Fragment>
      <Header />
      <GarageSection />
      <GarageDisplay />
      <ShowCase />
      <Footer />
    </React.Fragment>
  );
};

export default Home;
