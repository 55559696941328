import styled from "styled-components";
import { keyframes } from "styled-components";

export const ContainerPosition = styled.div`
  display: flex;
  position: absolute;
  top: 54%;
  left: calc(50% - 143.5px);
`;

export const Container = styled.div`
  position: relative;
`;

const runningBehind = keyframes`
    from
    {
        top: 37%;
    }
    to
    {
        top: 39%;
    }
`;

export const Car = styled.img`
  position: absolute;
  top: -25%;
  right: -15%;
  width: 125%;
  height: auto;
  z-index: 3;
`;

export const Sun = styled.img`
  z-index: 0;
`;
