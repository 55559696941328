import styled from "styled-components";
import noGearPunk from "../../assets/images/noGearPunk.png";
import reload from "../../assets/images/reload.png";

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #140b2e;
  max-width: 900px;
  margin: 100px auto 140px;
`;

export const Header = styled.div`
  width: 100%;
  height: 60px;
  background: #29009f;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const GearpunkContainer = styled.div`
  align-self: stretch;
  min-height: 350px;
  background-color: #BEA8FF;
`;

export const CryptopunkContainer = styled.div`
  align-self: stretch;
  min-height: 300px;
  background: #100922;
`;

export const Text = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  flex-grow: 1;
`;

export const CarContainer = styled.div`
  width: 90%;
  margin: 20px auto 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

export const NoGearpunkContainer = styled.div`
  background-image: url(${noGearPunk});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  max-width: 482px;
  height: 174px;
  margin: 50px auto 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Car = styled.img`
  height: 300px;
  width: 600px;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 425px) {
    height: 225px;
  }
`;

export const NoGearpunkText = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  margin-top: 40px;
`;

export const NoCryptopunkContainer = styled.div`
  max-width: 482px;
  margin: 5px auto 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const PunkContainer = styled.div`
  width: 100%;
  height: 220px;
  margin: 20px auto 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
`;

export const PunkNavigation = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  width: 91%;
`;

export const PunkActions = styled.div`
  display: flex;
`;

export const Punk = styled.img`
  height: auto;
  width: 125px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #778cd0;
  border: ${(props) => (props.selected ? "solid 3px white" : "none")};
`;

export const NoCryptopunkText = styled.p`
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  margin: 5px 0 0;
`;

export const NoCryptopunkImg = styled.img`
  margin-top: 45px;
`;

export const LeftArrow = styled.div`
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-right: 20px solid
    ${(props) => (props.disabled ? "rgba(255, 255, 255, 0.5)" : "#fff")};
  border-bottom: 20px solid transparent;
  cursor: pointer;
  float: left;
  padding-left: 15px;
  margin-top: 30px;

  @media only screen and (max-width: 425px) {
    border-top: 12px solid transparent;
    border-right: 12px solid
      ${(props) => (props.disabled ? "rgba(255, 255, 255, 0.5)" : "#fff")};
    border-bottom: 12px solid transparent;
  }
`;

export const RightArrow = styled.div`
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-left: 20px solid
    ${(props) => (props.disabled ? "rgba(255, 255, 255, 0.5)" : "#fff")};
  border-bottom: 20px solid transparent;
  cursor: pointer;
  float: right;
  padding-right: 15px;
  margin-top: 30px;

  @media only screen and (max-width: 425px) {
    border-top: 12px solid transparent;
    border-left: 12px solid
      ${(props) => (props.disabled ? "rgba(255, 255, 255, 0.5)" : "#fff")};
    border-bottom: 12px solid transparent;
  }
`;

export const ItemCounter = styled.div`
  width: 100%;
  text-align: center;
  position: absolute;
  top: 0px;
`;

export const Loader = styled.div`
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
  border-radius: 50%;
  width: 10em;
  height: 10em;

  @media only screen and (max-width: 425px) {
    font-size: 6px;
  }

  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

export const Reload = styled.div`
  position: absolute;
  right: 0;
  background-image: url(${reload});
  background-size: cover;
  width: 30px;
  height: 30px;
  margin-right: 50px;
  cursor: pointer;

  @media only screen and (max-width: 425px) {
    margin-right: 20px;
  }
`;
