export * as CryptoPunksABI from "./abis/CryptoPunks.json";
export * as GearPunksABI from "./abis/GearPunks.json";
export * as PunkDataABI from "./abis/PunkData.json";


export const MINT_GAS_LIMIT = 1_200_000

export const Networks = {
  1: {
    cryptoPunks: "0xb47e3cd837dDF8e4c57F05d70Ab865de6e193BBB",
    gearPunks: "0xe617797d1d6e6fc56aeb3905ee3042614ed77743",
    punkData: "0x16F5A35647D6F03D5D3da7b35409D65ba03aF3B2",
  },
  1337: {
    cryptoPunks: "0xb47e3cd837dDF8e4c57F05d70Ab865de6e193BBB",
    gearPunks: "0x8504898D0A80FE385eAD4d48319403ADcc627076",
    punkData: "0x16F5A35647D6F03D5D3da7b35409D65ba03aF3B2",
  },
  4: {
    cryptoPunks: "0xb47e3cd837dDF8e4c57F05d70Ab865de6e193BBB",
    gearPunks: "0x89e9B5ae6dBf81A0a128F27581d7df63BD104CDA",
    punkData: "0x16F5A35647D6F03D5D3da7b35409D65ba03aF3B2",
  },
};
