import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
  position: relative;
`;

export const Text = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  margin: 0;

  @media only screen and (max-width: 425px){
    font-size: 12px
  };
`;

export const TextContainer = styled.div`
  position: absolute;
  bottom: 80px;
`

export const Logo = styled.img`
  position: absolute;
  bottom: 160px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  max-width: 100%;

  @media(max-width: 767px){   
    max-width: 75%;
  }

  @media only screen and (max-width: 425px){
    max-width: 90%;
  }
`;

export const Color = styled.div`
  width: 100%;
  height: 70px;
  display: block;
`;

export const Color1 = styled(Color)`
  background-color: #120044;
`;

export const Color2 = styled(Color)`
  background-color: #1c006a;
`;

export const Color3 = styled(Color)`
  background-color: #29009f;
`;

export const Color4 = styled(Color)`
  background-color: #3700d5;
`;

export const Color5 = styled(Color)`
  background-color: #4200ff;
`;