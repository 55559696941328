import { BuildingBlack, BuildingBlue, BuildingsBehind, BuildingsForward, Container } from "./styles";

import BuildingBlackImage from "../../../assets/images/buildingBlack.png";
import BuildingBlueImage from "../../../assets/images/buildingBlue.png";

const Buildings = () => {
  return (
    <Container>
        <BuildingsForward>
            <BuildingBlack src={BuildingBlueImage} />
            <BuildingBlack src={BuildingBlueImage} />
        </BuildingsForward>
        <BuildingsBehind>
            <BuildingBlue src={BuildingBlackImage} />
            <BuildingBlue src={BuildingBlackImage} />
        </BuildingsBehind>
    </Container>
  );
};

export default Buildings;
