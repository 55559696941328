import React, { useEffect, useState } from "react";
import {
  Container,
  CryptopunkContainer,
  GearpunkContainer,
  Header,
  Text,
  CarContainer,
  Car,
  NoGearpunkContainer,
  NoGearpunkText,
  PunkContainer,
  Punk,
  NoCryptopunkContainer,
  NoCryptopunkImg,
  NoCryptopunkText,
  LeftArrow,
  RightArrow,
  ItemCounter,
  PunkNavigation,
  PunkActions,
  Loader,
  Reload,
} from "./style";
import { ButtonWrapper, Button } from "../GarageSection/style";
import noCryptoPunk from "../../assets/images/noCryptoPunk.png";
import { useTranslation } from "react-i18next";
import { useGearPunks } from "../../hooks/useGearPunks";
import _ from "lodash";

const parseMetadata = (raw) => {
  const [, encoded] = raw.split("data:application/json;base64,");
  return JSON.parse(Buffer.from(encoded, "base64").toString("utf-8"));
};

const GarageCollection = (props) => {
  const { t } = useTranslation();
  const { getCarTransfers, getPunkTransfers, getMetadata, readPunk } =
    useGearPunks();

  const [cars, setCars] = useState([]);
  const [punks, setPunks] = useState([]);

  const [loadingCars, setLoadingCars] = useState(true);
  const [loadingPunks, setLoadingPunks] = useState(true);

  const [currentCar, setCurrentCar] = useState(0);
  const [currentPunk, setCurrentPunk] = useState(0);

  const [reload, setReload] = useState(true);

  const { hopIn, hopOff } = useGearPunks();

  useEffect(() => {
    if (!reload) return;
    const getCars = async () => {
      setLoadingCars(true);
      const tokenIds = await getCarTransfers();
      if (tokenIds.length !== 0) {
        if (tokenIds.length !== 0) {
          // @todo get first token and then the rest
          const metadata = await Promise.all(
            tokenIds.map((id) => getMetadata(id))
          );
          const parsed = metadata.map((m) => {
            return {
              id: m.id,
              ...parseMetadata(m.metadata),
            };
          });
          setCars(parsed);
        }
      }
      setLoadingCars(false);
    };

    const getPunks = async () => {
      setLoadingPunks(true);
      const tokenIds = await getPunkTransfers();
      if (tokenIds.length !== 0) {
        // @todo get first token and then the rest
        const svgs = await Promise.all(tokenIds.map((id) => readPunk(id)));
        const encoded = svgs.map((svg) => {
          const [, raw] = svg.metadata.split("data:image/svg+xml;utf8,");
          return {
            id: svg.id,
            image:
              "data:image/svg+xml;base64," +
              Buffer.from(raw).toString("base64"),
          };
        });
        setPunks(encoded);
        setLoadingPunks(false);
      } else {
        setLoadingPunks(false);
      }
    };

    getCars();
    getPunks();
    setReload(false);
  }, [reload]); // @todo listen to block changes or transactions being mined

  const carLeft = () => {
    if (currentCar > 0) setCurrentCar(currentCar - 1);
  };
  const carRight = () => {
    if (currentCar < cars.length - 1) setCurrentCar(currentCar + 1);
  };
  const punkLeft = () => {
    if (currentPunk > 0) setCurrentPunk(currentPunk - 1);
  };
  const punkRight = () => {
    if (currentPunk < punks.length - 1) setCurrentPunk(currentPunk + 1);
  };

  const canHopIn = cars.length > 0;
  const punkTrait = (car) => _.find(car.attributes, { trait_type: "punk" });

  return (
    <Container>
      <GearpunkContainer>
        <Header>
          <Text>GEARPUNKS</Text>
          <Reload onClick={() => setReload(true)}></Reload>
        </Header>
        {loadingCars ? (
          <Loader />
        ) : cars.length === 0 ? (
          <NoGearpunkContainer>
            <NoGearpunkText>{t("no.gearpunk")}</NoGearpunkText>
          </NoGearpunkContainer>
        ) : (
          <CarContainer>
            <ItemCounter>{`${currentCar + 1}/${cars.length}`}</ItemCounter>
            <LeftArrow disabled={currentCar == 0} onClick={carLeft} />
            <Car src={cars[currentCar].image_data} alt="My GearPunk" />
            <RightArrow
              disabled={currentCar == cars.length - 1}
              onClick={carRight}
            />
          </CarContainer>
        )}
      </GearpunkContainer>
      <CryptopunkContainer>
        <Header>
          <Text>CRYPTOPUNKS</Text>
        </Header>
        {loadingPunks ? (
          <Loader />
        ) : punks.length === 0 ? (
          <NoCryptopunkContainer>
            <NoCryptopunkImg src={noCryptoPunk} />
            <NoCryptopunkText>{t("no.cryptopunk")}</NoCryptopunkText>
          </NoCryptopunkContainer>
        ) : (
          <PunkContainer>
            <PunkNavigation>
              <LeftArrow onClick={punkLeft} disabled={currentPunk == 0} />
              <Punk src={punks[currentPunk].image} alt="My GearPunk" />
              <RightArrow
                onClick={punkRight}
                disabled={currentPunk == punks.length - 1}
              />
            </PunkNavigation>
            <PunkActions>
              {cars.length && punkTrait(cars[currentCar]) ? (
                <Button
                  disabled={false}
                  onClick={() => hopOff(cars[currentCar].id)}
                >
                  {t("hop.off")}
                </Button>
              ) : (
                <Button
                  disabled={!canHopIn}
                  onClick={() =>
                    canHopIn
                      ? hopIn(cars[currentCar].id, punks[currentPunk].id)
                      : null
                  }
                >
                  {t("hop.in")}
                </Button>
              )}
            </PunkActions>
          </PunkContainer>
        )}
      </CryptopunkContainer>
    </Container>
  );
};

export default GarageCollection;
