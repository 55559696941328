import { LogoContainer, Text } from "./styles";
import LogoImg from "../../../assets/images/gearPunksLogo.png";

const Logo = () => {
   
  return (
    <>
      <Text>WELCOME TO</Text>
      <LogoContainer src={LogoImg} />
    </>
  )
};

export default Logo;
