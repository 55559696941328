import { Container, Subtitle, Title, TitleContainer } from "./style";
import ShowcaseList from "./ShowcaseList";
import gearPunks from "../../assets/images/gearPunks.png";

const Showcase = (props) => {
  return (
    <Container>
      <TitleContainer>
        <Title><img src={gearPunks} alt={'Gearpunks'}/></Title>
        <Subtitle>Showcase</Subtitle>
      </TitleContainer>
      <ShowcaseList />
    </Container>
  );
};

export default Showcase;
