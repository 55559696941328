import styled from "styled-components";
import { keyframes } from 'styled-components'

export const Container = styled.div`
    position: absolute;
    width: 100%;
    top: 258px; 
    display: flex;
`;

export const BuildingBlack = styled.img`
    display: block;
    height: 201px;
    border: none;
`;

export const BuildingBlue = styled.img`
    display: block;
    height: 201px;
    border: none;
`;

const runningBehind = keyframes`
    from
    {
        left: -100%;
    }
    to
    {
        left: 0px;
    }
`;

export const BuildingsBehind = styled.div`
    position: absolute;
    top: -10%;
    left: -Container%;
    display: flex;
    justify-content: space-between;
    width: 100%;
    animation: ${runningBehind} 20s infinite linear reverse;     
    z-index: 1;   
`;

const runningForward = keyframes`
    from
    {
        left: -100%;
    }
    to
    {
        left: 0px;
    }
`;

export const BuildingsForward = styled.div`
  position: absolute;
  top: -10%;
  left: -100%;
  display: flex;    
  justify-content: space-between;
  width: 100%;
  animation: ${runningForward} 7s infinite linear reverse; 
  z-index: 2;   
`;


