import {
  Animated,
  Container,
  Link,
  MenuMobile,
  Title,
  LinksContainer,
  ExternalLink,
  StyledBurger,
  StyledMenu,
} from "./style";
import { useNavigate } from "react-router-dom";
import Background from "./Background";
import Buildings from "./Buildings";
import CarAndSun from "./CarAndSun";
import Logo from "./Logo";
import AccountLabel from "./AccountLabel";
import { useTranslation } from "react-i18next";
import { useState } from "react";

const Header = (props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleClick = (url = "/") => {
    navigate(url);
  };

  return (
    <>
      <MenuMobile>
        <StyledBurger open={open} onClick={() => setOpen(!open)}>
          <div />
          <div />
          <div />
        </StyledBurger>

        <StyledMenu open={open}>
          <Title onClick={() => handleClick("/")}>GearPunks</Title>

          <Link onClick={() => handleClick("/garage")}>{t("my.garage")}</Link>
          <ExternalLink href="https://discord.gg/fbfJPprXsT">
            Discord
          </ExternalLink>
          <ExternalLink href="https://twitter.com/realGearPunks">
            Twitter
          </ExternalLink>

          <AccountLabel />
        </StyledMenu>
      </MenuMobile>
      <Animated>
        <LinksContainer>
          <Container>
            <Title onClick={() => handleClick("/")}>GearPunks</Title>
          </Container>
          <Container>
            <Link onClick={() => handleClick("/garage")}>{t("my.garage")}</Link>
            <ExternalLink href="https://discord.gg/fbfJPprXsT">
              Discord
            </ExternalLink>
            <ExternalLink href="https://twitter.com/realGearPunks">
              Twitter
            </ExternalLink>
            <Container>
              <AccountLabel />
            </Container>
          </Container>
        </LinksContainer>

        {!props.simplified ? (
          <>
            <Logo />
            <Background />
            <Buildings />
            <CarAndSun />
          </>
        ) : (
          <></>
        )}
      </Animated>
    </>
  );
};

export default Header;
