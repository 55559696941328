import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 75px;
`;

export const Line = styled.div`
  display: flex;
  margin-bottom: 32px;
  justify-content: center;

  @media(max-width: 767px){
    flex-direction: column;
    margin-bottom: 0;
  }
`;

export const SvgBase = styled.svg.attrs({
  version: "1.1",
  xmlns: "http://www.w3.org/2000/svg",
  xmlnsXlink: "http://www.w3.org/1999/xlink",
})``;

export const Svg = styled(SvgBase)`
  margin-left: 20px;
  margin-right: 20px;
  width: 144px;
  height: 144px;

  @media(max-width: 767px){ 
    margin-top: 15px;
  }
`;
