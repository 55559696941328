import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #140B2E;
  padding-top: 50px;
`;

export const Title = styled.h4`
  font-size: 24px;
  font-family: "Press Start 2P";
  text-align: center;
  text-transform: uppercase;
  line-height: 30px;
  font-weight: normal;
  margin-bottom: 21px;
  max-width: 500px;

  @media(max-width: 767px){    
    font-size: 16px;
  }  
`;

export const Subtitle = styled.p`
  font-size: 16px;
  line-height: 21px;
  max-width: 80%;
  text-align: center;
  font-weight: 500;

  @media(max-width: 767px){    
    font-size: 14px;
  }  
`;

export const Button = styled.div`
  background: ${props => props.disabled ? "#40077b" : "#8018f2"};
  border-radius: 8px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${props => props.disabled ? "not-allowed" : "pointer"};
  margin: 10px;
  margin-top: 32px;
  margin-bottom: 0px;
  font-weight: 700;
  font-size: 20px;
  padding: 0 45px;
`;

export const ButtonWrapper = styled.div`
  margin-bottom: 105px;
  display: flex;
  justify-content: space-between;
  padding: 10px;
`;
