import React from 'react'
import { useWallet } from 'use-wallet'
import { Networks } from '../../constants'

const SelectedChainIdContext = React.createContext()

export const ChainBoundaryProvider = ({children}) => {
    const wallet = useWallet()
    const isValidChainId = !!Networks[wallet.chainId]

    return (
        <SelectedChainIdContext.Provider value={isValidChainId}>
            {children}
        </SelectedChainIdContext.Provider>
    )
}

export const useChainBoundary = () => {
    const isValidChainId = React.useContext(SelectedChainIdContext)
    return isValidChainId
}