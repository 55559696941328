import Footer from "../../components/Footer";
import Header from "../../components/Header";
import GarageCollection from "../../components/GarageCollection";
import { ConnectButton } from "../../components/ConnectButton";

import MyGarage from "../../assets/images/myGarage.png";
import { useWallet } from 'use-wallet'

import {
  Button,
  Color1,
  Color2,
  Color3,
  ConnectWallectContainer,
  Text,
  TitleContainer,
  Title,
  Subtitle,
} from "./style";
import React from "react";
import { useTranslation } from "react-i18next";

const Garage = (props) => {
  const wallet = useWallet()
  const {t} = useTranslation()
  return (
    <React.Fragment>
      <Header simplified />
      <TitleContainer>
        <Color1 />
        <Color2 />
        <Color3 />
        <Title src={MyGarage} />
        <Subtitle>{t('garage.page.title')}</Subtitle>
      </TitleContainer>
      {wallet.status !== 'connected' ? (
        <ConnectWallectContainer>
          <Text>{t('garage.page.subtitle')}</Text>
          <ConnectButton>{t('connect.wallet')}</ConnectButton>
        </ConnectWallectContainer>
      ) : (
        <GarageCollection />
      )}
      <Footer />
    </React.Fragment>
  );
};

export default Garage;
