import { Car, Container, ContainerPosition, Sun } from "./styles";

import SunImg from "../../../assets/images/sun.png";
import CarImg from "../../../assets/images/carWithPunk1672.svg";

const CarAndSun = () => {
  return (
    <ContainerPosition>
      <Container>      
        <Sun src={SunImg} />
        <Car src={CarImg} />
      </Container>
    </ContainerPosition>
  );
};

export default CarAndSun;
