import {
  Container,
  Text,
  Color1,
  Color2,
  Color3,
  Color4,
  Color5,
  Logo,
  TextContainer,
} from "./style";
import LogoImg from "../../assets/images/gearPunksLogo.png";
import { useTranslation } from "react-i18next";

const Footer = (props) => {
  const { t } = useTranslation()
  return (
    <Container>
      <Color1 />
      <Color2 />
      <Color3 />
      <Color4 />
      <Color5 />

      <Logo src={LogoImg} />

      <TextContainer>
        <Text>© 2021 OnePercent.io - {t('rights.reserved')}</Text>
        <Text>
          {t('official.contract.address')} - 0xe617797d1d6e6fc56aeb3905ee3042614ed77743
        </Text>
      </TextContainer>
    </Container>
  );
};

export default Footer;
