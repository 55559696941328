import styled from "styled-components";

export const ContainerPosition = styled.div``;

export const LogoContainer = styled.img`
  position: absolute;
  top: 168px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  max-width: 100%;

  @media (max-width: 767px) {
    max-width: 70%;
  }

  @media only screen and (max-width: 425px) {
    max-width: 90%;
    top: 150px;
  }
`;

export const Text = styled.h5`
  position: absolute;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  font-family: "Press Start 2p";
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 24px;

  @media only screen and (max-width: 425px) {
    font-size: 20px;
    width: 100%;
    text-align: center;
  }
`;
