import styled from 'styled-components'

const NetworkCard = styled.div`
  border-radius: 12px;
  padding: 8px 12px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 0;
    margin-right: 0.5rem;
    width: initial;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 1;
  `};
`
export const AccountElement = styled.div`
  display: ${props => props.hide ? 'none' : 'flex'};
  flex-direction: row;
  align-items: center;
  border-radius: 6px;
  background-color: #2a2053;
  white-space: nowrap;
  width: 100%;
  padding: 1px;

  :focus {
    border: 1px solid blue;
  }
`
export const Balance = styled.div`
  padding-right: 10px;
  padding-left: 15px;
  display: inherit;
`

export const AccountAddress = styled.div`
  background-color: #000;
  padding: 7px;
  padding-left: 10px;
  border-radius: 6px;
`