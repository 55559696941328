import { AccountElement, AccountAddress, Balance } from './style'
import { useWallet } from "use-wallet";

const AccountLabel = (props) => {
    const wallet = useWallet()
    
    return (
        <AccountElement hide={!wallet.account}>
            <Balance>
                {(wallet.balance/Math.pow(10, 18)).toFixed(2)} ETH
            </Balance>
            <AccountAddress>
                {wallet.account ? 
                    wallet.account.replace(wallet.account.substring(5,38), "...") + ' 🔗' : ''
                }
            </AccountAddress>
        </AccountElement>
    )
}

export default AccountLabel