import styled from "styled-components";

export const Button = styled.div`
  background: #4200ff;
  border-radius: 8px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 32px;
  font-weight: 700;
  font-size: 20px;
  padding: 0 45px;

  @media only screen and (max-width: 425px) {
    font-size: 14px;
  }
`;

export const Error = styled.div`
  background: #ea3367;
  border-radius: 8px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 32px;
  font-weight: 700;
  font-size: 20px;
  padding: 0 45px;

  @media only screen and (max-width: 425px) {
    font-size: 14px;
  }
`;