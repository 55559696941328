import styled from "styled-components";
import ball from "../../assets/images/ball.png";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 150px;
`;

export const Title = styled.h4`
  max-width: 450px;
  text-align: center;
  font-family: "Press Start 2p";
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 24px;
  margin-top: 100px;
`;

export const GarageContainer = styled.div`
  display: flex;
  margin-top: 40px;
`;

export const BoxCar = styled.div`
  background-image: url(${ball});
  width: 144px;
  height: 144px;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 767px) {
    width: 90px;
    height: 90px;

    & img {
      width: 105px;
    }
  }
`;

export const BoxToon = styled.div`
  background-image: url(${ball});
  width: 144px;
  height: 144px;
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-size: contain;
  justify-content: center;

  @media (max-width: 767px) {
    width: 90px;
    height: 90px;

    & img {
      width: 54px;
    }
  }
`;

export const ImageCar = styled.img``;

export const ImageHead = styled.img``;

export const TextPlus = styled.div`
  font-weight: 500;
  font-size: 90px;
  line-height: 60px;
  text-align: center;
  color: #fdd7e6;
  display: flex;
  align-items: center;
  margin-left: 45px;
  margin-right: 34px;

  @media (max-width: 767px) {
    margin-left: 30px;
    margin-right: 23px;
    font-size: 55px;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 55px;

  @media (max-width: 767px) {
    padding: 0 10%;
  }
`;

export const Text = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  max-width: 550px;
  margin-bottom: 20px;

  @media (max-width: 767px) {
    font-size: 18px;
  }
`;
