import {
  BoxCar,
  BoxToon,
  Container,
  GarageContainer,
  TextPlus,
  Title,
  ImageCar,
  ImageHead,
  TextContainer,
  Text,
} from "./style";
import car from "../../assets/images/car.png";
import head from "../../assets/images/head.png";
import { useTranslation } from "react-i18next";

const GarageDisplay = (props) => {
  const { t } = useTranslation()
  return (
    <Container>
      <Title>{t('garage.display.title')}</Title>

      <GarageContainer>
        <BoxCar>
          <ImageCar src={car} />
        </BoxCar>
        <TextPlus>+</TextPlus>
        <BoxToon>
          <ImageHead src={head}  />
        </BoxToon>
      </GarageContainer>

      <TextContainer>
        <Text>{t('garage.display.step.one')}</Text>
        <Text>{t('garage.display.step.two')}</Text>
        <Text>{t('garage.display.step.three')}</Text>
        <Text>{t('garage.display.step.four')}</Text>
      </TextContainer>
    </Container>
  );
};

export default GarageDisplay;
