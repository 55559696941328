import { Suspense } from 'react'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Home, Garage } from "./views/index";
import { UseWalletProvider } from "use-wallet"
import { ChainBoundaryProvider, useChainBoundary } from './components/ChainBoundaryProvider';
import Header from './components/Header'
import Footer from './components/Footer'
import { Subtitle } from './components/Showcase/style';

function App() {
  return (
    <Suspense fallback={null}>
      <UseWalletProvider chainId={parseInt(process.env.REACT_APP_CHAIN_ID)}>
        <ChainBoundaryProvider>
          <AppRouter />
        </ChainBoundaryProvider>
      </UseWalletProvider>
    </Suspense>
  );
}

export default App;

const AppRouter = () => {
  const isValidChainId = useChainBoundary()

  if (isValidChainId) {
    return (
      <Router>
        <div className="App">
          <Routes>
            <Route path="/garage" element={<Garage />} />
            <Route path="/" element={<Home />} />
          </Routes>
        </div>
      </Router>
    )
  }

  return (
    <Router>
      <div className="App">
        <Header />
        <Subtitle>
          Please change the network in your metamask to mainnet
        </Subtitle>
        <Footer />
      </div>
    </Router>
  )
}