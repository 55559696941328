import { __DEV__ } from '../constants'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

const supportedLanguages = ["en-US", "pt-BR"]
const defaultLanguage = supportedLanguages[0]

i18n.use(LanguageDetector).use(initReactI18next).use(HttpApi).init({
  backend: { loadPath: '/locales/{{lng}}.json' },
  fallbackLng: defaultLanguage,
  react: {
    useSuspense: true,
  },
  preload: [defaultLanguage],
  keySeparator: false,
  interpolation: { escapeValue: false },
  debug: __DEV__,
})