import styled from "styled-components";

export const Container = styled.div`
  display: block;
`;

export const Color = styled.div`
  width: 100%;
  height: 27px;
  display: block;
`;

export const Color1 = styled(Color)`
  background-color: #040011;
`;

export const Color2 = styled(Color)`
  background-color: #120044;
`;

export const Color3 = styled(Color)`
  background-color: #1C006A;
`;

export const Color4 = styled(Color)`
  background-color: #29009F;
`;

export const Color5 = styled(Color)`
  background-color: #3700D5;
`;

export const Color6 = styled(Color)`
  background-color: #4200FF;
`;

export const Color7 = styled(Color)`
  background-color: #8F00FF;
`;

export const Color8 = styled(Color)`
  background-color: #CC00FF;
`;

export const Color9 = styled(Color)`
  background-color: #FF00F5;
`;

export const Color10 = styled(Color)`
  background-color: #FF00A8;
`;

export const Color11 = styled(Color)`
  background-color: #FF0065;
`;

export const Color12 = styled(Color)`
  background-color: #FF2B7E;
`;

export const Color13 = styled(Color)`
  background-color: #FF5397;
`;

export const Color14 = styled(Color)`
  background-color: #FF5397;
`;

export const Color15 = styled(Color)`
  background-color: #FF7CB0;
`;

export const Color16 = styled(Color)`
  background-color: #FF9CC3;
`;

export const Color17 = styled(Color)`
  background-color: #FFB9D5;
`;

export const Road = styled.div`
  background-color: #1B1629;
  width: 100%;
  height: 0;
  display: block;
`;