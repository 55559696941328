import { Color1, Color2, Color3, Color4, Color5, Color6, Color7, Color8, Color9, Color10, Color11, Color12, Color13, Color14, Color15, Color16, Color17, Container, Road} from "./styles";

const Background = () => {
      
    return (
      <Container>
        <Color1 />
        <Color2 />
        <Color3 />
        <Color4 />
        <Color5 />
        <Color6 />
        <Color7 />
        <Color8 />
        <Color9 />
        <Color10 />
        <Color11 />
        <Color12 />
        <Color13 />        
        <Color14 />
        <Color15 />
        <Color16 />
        <Color17 /> 
        <Road />
      </Container>
    );
  };
  
  export default Background;
  