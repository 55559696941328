import { Container, Subtitle, Title, ButtonWrapper, Button } from "./style";
import { ConnectButton } from '../ConnectButton'
import { useWallet } from 'use-wallet'
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGearPunks } from "../../hooks/useGearPunks";

const GarageSection = (props) => {
  const wallet = useWallet()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { mint } = useGearPunks()

  const handleClick = (url = "/") => {
    navigate(url);
  };

  return (
    <Container>
      <Title>{t('garage.section.title')}</Title>
      <Subtitle>
      {t('garage.section.subtitle')}
      </Subtitle>
      { wallet.account ? 
        <ButtonWrapper>
          <Button onClick={mint}>{t('mint')}</Button>
          <Button onClick={() => handleClick("/garage")}>{t('my.garage')}</Button>
        </ButtonWrapper>
        :
        <ButtonWrapper>
          <ConnectButton>{t('connect.wallet')}</ConnectButton>
        </ButtonWrapper>
      }
    </Container>
  );
};

export default GarageSection