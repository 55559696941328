import styled from "styled-components";

export const TitleContainer = styled.div``;

export const Color = styled.div`
  width: 100%;
  height: 40px;
  display: block;
`;

export const Color1 = styled(Color)`
  background: #29009f;
`;
export const Color2 = styled(Color)`
  background: #1c006a;
`;
export const Color3 = styled(Color)`
  background: #120044;
`;

export const Title = styled.img`
  position: absolute;
  top: 125px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  max-width: 100%;

  @media only screen and (max-width: 425px) {
    max-width: 90%;
  }
`;

export const Subtitle = styled.h5`
  position: absolute;
  top: 150px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  font-weight: 500;
  font-size: 24px;

  @media only screen and (max-width: 900px) {
    text-align: center;
    width: 100%;
    transform: unset;
    left: 0px;
    font-size: 16px;
    top: 160px;
  }
`;

export const ConnectWallectContainer = styled.div`
  max-width: 900px;
  height: 275px;
  background: #140b2e;
  margin: 80px auto 150px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: 425px) {
    margin: 60px auto 110px;
  }
`;

export const Text = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  max-width: 285px;
  margin: 60px 0 38px 0;

  @media only screen and (max-width: 425px) {
    font-size: 16px;
  }
`;

export const Button = styled.div`
  background: #4200ff;
  border-radius: 8px;
  height: 56px;
  width: 270px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 700;
  font-size: 20px;
  padding: 0 45px;
`;
