import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h3`
  font-family: "Press Start 2P";
  color: #ff0065;
  font-size: 18px;
  line-height: 18px;
  margin-bottom: 15px;
`;

export const Subtitle = styled.h4`
  font-family: "Press Start 2p";
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  text-align: center;
  text-transform: uppercase;
  margin: 110px auto;
  text-transform: uppercase;
  max-width: 600px;
  line-height: 54px;
`;
