import { ReactComponent as Car23 } from "../../../assets/icons/Car23.svg";
import { ReactComponent as Car53 } from "../../../assets/icons/Car53.svg";
import { ReactComponent as Car54 } from "../../../assets/icons/Car54.svg";
import { ReactComponent as Car55 } from "../../../assets/icons/Car55.svg";
import { ReactComponent as Car56 } from "../../../assets/icons/Car56.svg";
import { ReactComponent as Car57 } from "../../../assets/icons/Car57.svg";
import { ReactComponent as Car58 } from "../../../assets/icons/Car58.svg";

import { Container, Line, Svg } from "./style";

const ShowcaseList = (props) => {
  return (
    <Container>
      <Line>
        <Svg>
          <Car58 />
        </Svg>
        <Svg>
          <Car54 />
        </Svg>
        <Svg>
          <Car56 />
        </Svg>
        <Svg>
          <Car57 />
        </Svg>
      </Line>

      <Line>
        <Svg>
          <Car55 />
        </Svg>

        <Svg>
          <Car23 />
        </Svg>

        <Svg>
          <Car53 />
        </Svg>
      </Line>
    </Container>
  );
};

export default ShowcaseList;
