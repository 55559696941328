import { Button, Error } from './style'
import { useWallet, ChainUnsupportedError } from "use-wallet";
import { Networks } from '../../constants/blockchain';

export const ConnectButton = (props) => {
    const wallet = useWallet()
 
    return (
        !Networks[wallet.chainId] ? 
            <Error>Unsuported Network</Error> :
            <Button onClick={() => wallet.connect()}>{props.children}</Button>
    )
}